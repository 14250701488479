import React, { useMemo } from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];

const SectionGallery = props => {
  const { listing, variantPrefix } = props;
  const { images, attributes } = listing || {};
  const { title, publicData } = attributes || {};
  const { featuredBrandImageId } = publicData || {};

  const thumbnailVariants = useMemo(
    () => [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`],
    [variantPrefix]
  );

  return (
    <div className={css.productGallery} data-testid="carousel">
      <ListingImageGallery
        images={images}
        featuredBrandImageId={featuredBrandImageId}
        listingTitle={title}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
      />
    </div>
  );
};

export default SectionGallery;
