import React from 'react';
import { IconEdit, IconSparkle } from '../../components';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';

import css from './ListingPage.module.css';
import { listingConditions } from '../../config/configListing';

const SectionConditionMaybe = props => {
  const { publicData, listingConfig, intl } = props;
  const { listingType } = publicData || {};

  if (!publicData || !listingConfig) {
    return null;
  }

  switch (listingType) {
    case 'kids_bamboo': {
      const { kids_clothing_condition, condition_notes_textarea, clothing_condition_specs } =
        publicData || {};

      const hasAny =
        kids_clothing_condition || condition_notes_textarea || clothing_condition_specs;

      const condition =
        kids_clothing_condition &&
        listingConditions.find(c => c.abbr.toUpperCase() === kids_clothing_condition.toUpperCase());

      const clothingConditionSpecsConfig =
        hasAny && listingConfig.listingFields.find(({ key }) => key === 'clothing_condition_specs');

      const createFilterOptions = options =>
        options.map(o => ({ key: `${o.option}`, label: o.label }));

      return hasAny ? (
        <section className={css.conditionSection} style={{ borderTopColor: condition.accentColor }}>
          {condition ? (
            <div>
              <div className={css.conditionHeading}>
                <IconSparkle className={css.conditionHeadingIcon} />
                <span>Condition: </span>
                <span style={{ color: condition.textColor }}>{condition.title}</span>
              </div>
              <div className={css.conditionContent}>
                <span>{condition.text}</span>
              </div>
            </div>
          ) : null}
          {condition_notes_textarea ? (
            <div>
              <div className={css.conditionHeading}>
                <IconEdit className={css.conditionHeadingIconPen} />
                <span>Seller's Comments</span>
              </div>
              <div className={css.conditionContent}>{condition_notes_textarea}</div>
            </div>
          ) : null}
          {clothing_condition_specs ? (
            <div>
              <div className={css.conditionContent}>
                <SectionMultiEnumMaybe
                  className={css.conditionContentMultiEnum}
                  key={clothingConditionSpecsConfig.key}
                  options={createFilterOptions(clothingConditionSpecsConfig.enumOptions)}
                  selectedOptions={clothing_condition_specs}
                />
              </div>
            </div>
          ) : null}
        </section>
      ) : null;
    }
    default:
      return null;
  }
};

export default SectionConditionMaybe;
